import React from "react";
import { ImageAvatar, ImageAvatarSizes, DefaultPetProfile } from "ui";

export default function PetProfilePhoto({
  src,
  petName,
  size = "md",
  rounded = "t-3xl",
  classes = "",
  background = "",
  defaultImgClasses = 'justify-center',
}: {
  src?: string | null;
  classes?: string;
  petName?: string;
  size?: ImageAvatarSizes;
  rounded?: string;
  background?: string;
  defaultImgClasses?: string;
}) {
  return (
    <ImageAvatar
      size={size}
      src={src as string}
      alt={`${petName}'s Profile Photo`}
      defaultAvatar={
        <div
          className={`mt-5 flex ${defaultImgClasses}`}
        >
          <DefaultPetProfile className="h-32 w-60 lg:h-40 lg:w-80" />
        </div>
      }
      rounded={rounded}
      classes={classes}
      background={background}
    />
  );
}
