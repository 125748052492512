import { Routes, Route, useNavigate } from "react-router-dom";
import { AppProtectedLayout, Button, Loader, SideMenu } from "ui";
import { useTranslation } from "react-i18next";
import { Profile, DashBoard, ErrorPage, PetLoader } from "./components";
import SignIn from "./components/SignIn";
import { AddEditPet, PetDashBoard } from "./components/Pet/";
import { CustomNavLink } from "./components/CustomNavLink/";
import LifestageMilestoneForm from "./components/Pet/LifestageMilestoneForm/LifestageMilestoneForm";
import {
  AUTHENTICATION_STATUS,
  SIDEMENU_HEADERS,
  SIDEMENU_ITEMS,
} from "./constants";
import { useRef, useCallback, useEffect } from "react";
import { Auth, useAuth, User } from "./hooks/use-auth";
import gigya from "./lib/gigya";
import { ToastProvider } from "./lib/toast";
import "./lib/api";
import { ROUTES, ROUTES_PATH } from "./constants/routes";
import { PetConfigProvider } from "./hooks/usePetConfig";
import ProfilePhotoUpload from "./components/Profile/ProfilePhotoUpload";

function App() {
  const restrictLoginEventCall = useRef(false);
  const auth: Auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //If user is already authenticated navigate to Dashboard page
  if (
    auth.status === AUTHENTICATION_STATUS.AUTHENTICATED &&
    (location.hash === "#/" || location.hash === "") &&
    localStorage.getItem("gigyaToken") !== null
  ) {
    navigate(ROUTES_PATH.DASHBOARD, { replace: true });
  }

  const onLogin = useCallback(
    (response: User) => {
      auth.generateAuthToken && auth.generateAuthToken(response);
    },
    [auth]
  );

  const onLogout = useCallback(() => {
    auth.afterLogout && auth.afterLogout();
  }, [auth]);

  useEffect(() => {
    if (gigya !== undefined && !restrictLoginEventCall.current && auth?.inAppAuth) {
      gigya.accounts.addEventHandlers({
        onLogin: onLogin,
        onLogout: onLogout,
      });
      restrictLoginEventCall.current = true;
    }
  }, [onLogin, onLogout]);

  const handleLogout = () => {
    auth.logout && auth.logout();
  };

  return (
    <ToastProvider>
      <PetConfigProvider>
        <div className="App flex flex-col font-montserrat">
          {auth.status === AUTHENTICATION_STATUS.AUTHENTICATED &&
            auth?.inAppAuth && (
              <div className="float-right m-2 rounded py-2 px-4">
                <Button
                  variant="secondary"
                  size="sm"
                  type="button"
                  onClick={() => handleLogout()}
                >
                  Logout
                </Button>
              </div>
            )}
          <Routes>
            <Route
              index
              element={auth?.inAppAuth ? <SignIn /> : <PetLoader />}
            />
            <Route path={ROUTES.DASHBOARD} element={<DashBoard />} />
            <Route
              path={ROUTES.PROFILE}
              element={
                <AppProtectedLayout
                  sideMenu={
                    <SideMenu
                      items={SIDEMENU_ITEMS}
                      render={(menuItem, iconOnly) => (
                        <CustomNavLink
                          linkItem={menuItem}
                          iconOnly={iconOnly}
                        />
                      )}
                      profilePic={
                        <ProfilePhotoUpload
                          photoUrl={auth?.user?.profile?.photoURL}
                        />
                      }
                      subHeading={
                        t(SIDEMENU_HEADERS.subHeading) +
                          " " +
                          auth.user?.profile?.firstName || "Back"
                      }
                    />
                  }
                  content={<Profile />}
                />
              }
            />
            <Route
              path={ROUTES.PET}
              element={
                <AppProtectedLayout
                  sideMenu={
                    <SideMenu
                      items={SIDEMENU_ITEMS}
                      render={(menuItem, iconOnly) => (
                        <CustomNavLink
                          linkItem={menuItem}
                          iconOnly={iconOnly}
                        />
                      )}
                      profilePic={
                        <ProfilePhotoUpload
                          photoUrl={auth?.user?.profile?.photoURL}
                        />
                      }
                      subHeading={
                        t(SIDEMENU_HEADERS.subHeading) +
                        " " +
                        auth.user?.profile?.firstName
                      }
                    />
                  }
                  content={<AddEditPet />}
                />
              }
            />
            <Route
              path={ROUTES.ADD_PET}
              element={
                <AppProtectedLayout
                  sideMenu={
                    <SideMenu
                      items={SIDEMENU_ITEMS}
                      render={(menuItem, iconOnly) => (
                        <CustomNavLink
                          linkItem={menuItem}
                          iconOnly={iconOnly}
                        />
                      )}
                      profilePic={
                        <ProfilePhotoUpload
                          photoUrl={auth?.user?.profile?.photoURL}
                        />
                      }
                      subHeading={
                        t(SIDEMENU_HEADERS.subHeading) +
                        " " +
                        auth.user?.profile?.firstName
                      }
                    />
                  }
                  content={<AddEditPet />}
                />
              }
            />
            <Route
              path={ROUTES.MILESTONE}
              element={
                <AppProtectedLayout
                  sideMenu={
                    <SideMenu
                      items={SIDEMENU_ITEMS}
                      render={(menuItem, iconOnly) => (
                        <CustomNavLink
                          linkItem={menuItem}
                          iconOnly={iconOnly}
                        />
                      )}
                      profilePic={
                        <ProfilePhotoUpload
                          photoUrl={auth?.user?.profile?.photoURL}
                        />
                      }
                      subHeading={
                        t(SIDEMENU_HEADERS.subHeading) +
                        " " +
                        auth.user?.profile?.firstName
                      }
                    />
                  }
                  content={<LifestageMilestoneForm />}
                />
              }
            />
            <Route
              path={ROUTES.PET_DASHBOARD}
              element={
                <AppProtectedLayout
                  sideMenu={
                    <SideMenu
                      items={SIDEMENU_ITEMS}
                      render={(menuItem, iconOnly) => (
                        <CustomNavLink
                          linkItem={menuItem}
                          iconOnly={iconOnly}
                        />
                      )}
                      profilePic={
                        <ProfilePhotoUpload
                          photoUrl={auth?.user?.profile?.photoURL}
                        />
                      }
                      subHeading={
                        t(SIDEMENU_HEADERS.subHeading) +
                        " " +
                        auth.user?.profile?.firstName
                      }
                    />
                  }
                  content={<PetDashBoard />}
                />
              }
            />
            <Route path={ROUTES.ERROR_PAGE} element={<ErrorPage />} />
          </Routes>
          {auth.isTokenGenerating && (
            <div className=" absolute h-full w-full bg-white">
              <div className="relative top-1/2 text-center">
                <Loader />
                <span className="text-3xl">
                  Please Wait! We are logging you in...
                </span>
              </div>
            </div>
          )}
        </div>
      </PetConfigProvider>
    </ToastProvider>
  );
}

export default App;
