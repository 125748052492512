import React, { forwardRef, InputHTMLAttributes } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse, isValid, toDate, Locale } from "date-fns";
import { Field } from "react-final-form";
import { enGB, ptBR } from "date-fns/locale";
import { IconCalendar } from "ui";
import { DATE_FORMAT } from "utils";
import { LANG } from "../../constants/languages";
import useRequireAuth from "../../hooks/useRequireAuth";

interface DatepickerConfig {
  name: string;
  label?: string;
  onBlurCapture?: (e: { target: { name: string; value: string } }) => void;
  validate?: (value: string | undefined) => string | undefined;
  disabled?: boolean;
  min?: Date;
  max?: Date;
  optionalText?: string;
}

interface ILocaleFormatMap {
  [key: string]: { locale: Locale; placeholder: string };
}

const CustomInput = forwardRef<HTMLInputElement>(
  (input: InputHTMLAttributes<HTMLInputElement>, ref) => {
    return (
      <div className="flex">
        <input {...input} ref={ref}></input>
        <div
          className="z-0 -ml-8 flex w-7 flex-row-reverse items-center"
          onClick={input.onClick}
        >
          <IconCalendar />
        </div>
      </div>
    );
  }
);

const LocaleFormatMap: ILocaleFormatMap = {
  [LANG.BR]: {
    locale: ptBR,
    placeholder: "DD/MM/AAAA",
  },
  [LANG.EN]: {
    locale: enGB,
    placeholder: "DD/MM/YYYY",
  },
};

export const Datepicker = (props: DatepickerConfig) => {
  registerLocale(LANG.BR, ptBR);
  registerLocale(LANG.EN, enGB);

  const { locale } = useRequireAuth();

  return (
    <div className="mt-1 w-full">
      <label
        className={`self-baseline text-xl font-bold ${
          props.disabled ? "opacity-40" : ""
        }`}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <Field name={props.name} validate={props.validate && props.validate}>
        {({ input, meta }) => {
          let isValidDate = false;
          let selected = null;

          if (input.value) {
            const parsedDate = parse(
              input.value as unknown as string,
              "P",
              new Date()
            );
            isValidDate = isValid(parsedDate);
            selected = isValidDate ? toDate(parsedDate) : null;
          }
          
          return (
            <>
              <DatePicker
                id={input.name}
                name={input.name}
                disabled={props.disabled || false}
                locale={locale || "en"}
                dateFormat="P"
                selected={selected}
                onBlur={input.onBlurCapture}
                customInput={<CustomInput />}
                placeholderText={
                  input.value
                    ? format(new Date(input.value?.replace(/-/g, '/')), "P", {
                        locale:
                          LocaleFormatMap[(locale as string) || "en"]
                            ?.locale || enGB,
                      })
                    : LocaleFormatMap[(locale as string) || "en"]
                        ?.placeholder || "DD/MM/YYYY"
                }
                className="h-12 w-full rounded-md border-2 p-2 disabled:opacity-40"
                onChange={(date) => {
                  if (isValid(date)) {
                    input.onChange(format(date as Date, DATE_FORMAT));
                  } else {
                    input.onChange(null);
                  }
                }}
                onCalendarClose={() => {
                  input.value &&
                    props.onBlurCapture &&
                    props.onBlurCapture({
                      target: {
                        name: input.name,
                        value: input.value,
                      },
                    });
                }}
                yearDropdownItemNumber={5}
                minDate={props.min}
                maxDate={props.max || new Date()}
                showYearDropdown
                dropdownMode="select"
                popperPlacement="bottom-end"
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [1, 1],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                  {
                    name: "arrow",
                    options: {
                      padding: 20,
                    },
                  },
                ]}
              />
              {props.optionalText && (
                <span className="text-xs text-slate-500">
                  {props.optionalText}
                </span>
              )}
              <div className="mt-1 h-4">
                {meta.error && meta.submitFailed && (
                  <div className="mb-2 text-xs text-brand-error">
                    {`${props.label && props.label} ${meta.error}`}
                  </div>
                )}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};

Datepicker.displayName = "Datepicker";
