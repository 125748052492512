import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import axios from "axios";
import { useApi } from "utils";
import { API_URLS } from "../constants/apiUrls";
import { PetConfigType, PetSizeType } from "../../types";
import { Auth, useAuth } from "./use-auth";
import { AUTHENTICATION_STATUS } from "../constants";

const initData = {
  breeds: [],
  lifeStages: [],
  petTypes: [],
  petSizes: [],
  petStatus: [],
  petRemovalReasons: [],
  petGender: [],
  //client side state
  petTypesByName: { Dog: 1, Cat: 2 },
  petStatusByName: { Active: 1, Remembered: 2, Deleted: 3 },
  petRemovalReasonById: { 1: "Rehomed", 2: "Deceased" },
};
const PetConfigContext = createContext<PetConfigType>(initData);

export function PetConfigProvider({ children }: { children: React.ReactNode }) {
  const config = useProvidePetConfig();

  function getBreed(breedId: number | null) {
    return config.breeds.find((item) => item.petBreedId === breedId);
  }
  function getLifeStageName(id: number) {
    return config.lifeStages.find((item) => item.petLifeStageId === id)
      ?.petLifeStageName as string;
  }
  function getLifeStageDesc(id: number) {
    return config.lifeStages.find((item) => item.petLifeStageId === id)
      ?.petLifeStageDesc as string;
  }
  return (
    <PetConfigContext.Provider
      value={{ ...config, getBreed, getLifeStageName, getLifeStageDesc }}
    >
      {children}
    </PetConfigContext.Provider>
  );
}

export const usePetConfig = () => {
  return useContext<PetConfigType>(PetConfigContext);
};

function useProvidePetConfig() {
  const [petConfig, setPetConfig] = useState<PetConfigType>(initData);
  const abortControllerRef = useRef<AbortController | null>(null);
  const auth: Auth = useAuth();

  const { exec: fetchPetConfig } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PET_CONFIG}`, {
        signal: abortController.signal,
        headers: { locale: auth.locale as string },
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          const petStatusObj = apiResponse.data.data.petStatus.reduce(
            (
              accumulator: object,
              value: { petStatusId: number; petStatus: string }
            ) => {
              return { ...accumulator, [value.petStatus]: value.petStatusId };
            },
            {}
          );

          const petRemovalReasonObj =
            apiResponse.data.data.petRemovalReasons.reduce(
              (accumulator: object, value: { id: number; name: string }) => {
                return { ...accumulator, [value.id]: value.name };
              },
              {}
            );
          const petTypesByName = apiResponse.data.data.petTypes.reduce(
            (
              accumulator: object,
              value: { petTypeId: number; petTypeName: string }
            ) => {
              return {
                ...accumulator,
                [value.petTypeName]: value.petTypeId.toString(),
              };
            },
            {}
          );
          apiResponse.data.data.petSizes.forEach(
            (v: PetSizeType) => (v.petSizeId += "")
          );
          apiResponse.data.data["petTypesByName"] = petTypesByName;
          apiResponse.data.data["petStatusByName"] = petStatusObj;
          apiResponse.data.data["petRemovalReasonById"] = petRemovalReasonObj;
          setPetConfig(apiResponse.data.data);
        }
      });
  });

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (auth.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      fetchPetConfig();
    }
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, [auth.status]);

  return petConfig;
}
