import React from "react";
import { IconCatDog } from "ui";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:flex-row mx-9 flex h-screen flex-col-reverse place-items-center justify-center gap-5">
      <div className="w-11/12 lg:w-1/4">
        <h1 className="mb-6 text-5xl md:text-8xl font-bold  text-brand-primary">{t("common.woof")}...</h1>
        <p className="lg:mb-6 mb-4 md:text-2xl text-lg font-normal">
          {t("common.unAuthError")}
        </p>
        <p className="md:text-2xl text-lg font-semibold">{t("common.ssoError")}</p>
      </div>
      <div className="lg:w-4/12">
        <IconCatDog className="md:h-44 md:w-96 h-36 w-64  " />
      </div>
    </div>
  );
};

export default ErrorPage;
