import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, IconBigCalendar, IconFoodFinder } from "ui";
import { calculateAgeApprox } from "utils";
import { PetType, PetTypes } from "../../../../../types";
import {
  PET,
  PET_FOOD_FINDER_CAT,
  PET_FOOD_FINDER_DOG,
  ROUTES_PATH,
} from "../../../../constants";
import { usePetConfig } from "../../../../hooks/usePetConfig";
import PetProfilePhoto from "../../PetProfilePhoto";

function PetAction({
  icon,
  title,
  buttonText,
  buttonCallback,
}: {
  icon: React.ReactNode;
  title: string;
  buttonText: string | React.ReactNode;
  buttonCallback: () => void;
}) {
  return (
    <div className="flex gap-x-7">
      {icon}
      <div className="flex w-full flex-col md:block">
        <h3 className="mb-3 text-center text-base font-bold md:text-left lg:text-xl">
          {title}
        </h3>
        <Button
          variant="primary"
          mode="contained"
          onClick={buttonCallback}
          aria-label={buttonText}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

function PetCharacteristic({ title, value }: { title: string; value: string }) {
  const { t } = useTranslation();
  return (
    <h3 className="my-2 text-center text-base font-bold md:my-1">
      <span className="mr-2">{t(`addEditPet.${title.toLowerCase()}`)}</span>
      <span className="font-normal">{value}</span>
    </h3>
  );
}

export function PetName({ name }: { name: string }) {
  return (
    <h2 className="text-lg font-extrabold text-white md:text-2xl capitalize">{name}</h2>
  );
}

const PetDetails = ({ data }: { data: PetType }) => {
  const { t } = useTranslation();
  const { getBreed, petTypes } = usePetConfig();
  const navigate = useNavigate();

  const getPetFood = (petTypeId: number) => {
    const petType = petTypes.find(
      (pet: PetTypes) => pet.petTypeId === parseInt(petTypeId.toString())
    );
    petType && petType.petTypeName
      ? window.open(
          petType.petTypeName === "Dog"
            ? PET_FOOD_FINDER_DOG
            : PET_FOOD_FINDER_CAT,
          "_blank"
        )
      : null;
  };

  return (
    <div className="mb-10 grid grid-cols-none md:mb-16 md:grid-cols-8">
      <div className="col-span-4 md:col-span-3 md:mr-6">
        <PetProfilePhoto
          src={data.petImage}
          size="md"
          rounded="2xl"
          petName={data.petName}
          classes="lg:mx-0 mx-auto w-48 md:w-80"
          defaultImgClasses="justify-center md:justify-start"
        />
        <div className="grid w-full grid-cols-1 2xl:w-10/12">
          <Button
            aria-label={t(PET.EDIT_PROFILE)}
            mode="link"
            variant="primary"
            onClick={() =>
              navigate(ROUTES_PATH.PET, {
                state: { petId: data.petId },
                replace: false,
              })
            }
          >
            {t(PET.EDIT_PROFILE)}
          </Button>
          <hr className="mx-20 my-6 hidden h-px border-0 bg-brand-accent-gray-light md:block"></hr>
          <PetCharacteristic
            title="Breed"
            value={
              (data.petBreedId ? (getBreed && getBreed(data.petBreedId)?.petBreedName) : t("pet.notSelectedText")) as string
            }
          />
          <PetCharacteristic
            title="Age"
            value={(data.petBirthday ? calculateAgeApprox(data.petBirthday, t) : t("pet.notSelectedText")) as string}
          />
        </div>
      </div>
      <div className="col-span-4 mt-6 flex flex-col gap-y-9 md:col-span-5">
        <p className="mb-0 text-center text-base md:mb-5 md:text-left md:text-lg">
          {t("pet.petToolsDesc")}
        </p>
        <PetAction
          title={t(PET.MILESTONE_BUTTON_HEADING)}
          buttonText={t(PET.MILESTONE_BUTTON_TEXT)}
          icon={<IconBigCalendar className="hidden h-20 md:inline-block" />}
          buttonCallback={() => {
            navigate(
              `${ROUTES_PATH.MILESTONE}/${data.petId}/${data.petLifeStageId}`,
              {
                replace: false,
              }
            );
          }}
        />
        <PetAction
          title={t(PET.FOOD_QUIZ_HEADING)}
          buttonText={t(PET.FOOD_QUIZ_BUTTON_TEXT)}
          icon={<IconFoodFinder className="hidden h-20 md:inline-block" />}
          buttonCallback={() => {
            getPetFood(data.petTypeId);
          }}
        />
      </div>
    </div>
  );
};

export default PetDetails;
