import React, { createContext, useContext, useState } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { IconWarning, IconToastSuccess, IconToastCross, IconCloseLg } from "ui";

// Interface

interface ToastConfig {
  title?: string;
  description?: string;
  type?: "error" | "success" | "warning";
  duration?: number;
}
interface ToastStates {
  toastConfig?: ToastConfig;
  toastState: boolean;
  setToastState: (open: boolean) => void;
}
interface ToastContext {
  showToast: (config: ToastConfig) => void;
  hideToast: () => void;
  toggleToast?: () => void;
}
const toastTypes = {
  error: "bg-red-100 border-2 border-red-400 text-red-800",
  success: "bg-green-100 border-2 border-green-400 text-green-800",
  warning: "bg-orange-100 border-2 border-orange-400 text-orange-700",
};
// Exports
export const ToastClose = ToastPrimitive.Close;

const ToastContext = createContext<ToastContext>({} as ToastContext);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastState, setToastState] = useState(false);
  const [toastConfig, setToastConfig] = useState<ToastConfig>({});

  const toggleToast = () => {
    setToastState(!toastState);
  };

  const showToast = (config: ToastConfig) => {
    setToastConfig(config);
    setToastState(true);
  };

  const hideToast = () => {
    setToastState(false);
  };
  return (
    <ToastContext.Provider value={{ showToast, hideToast, toggleToast }}>
      {children}
      <Toast
        toastConfig={toastConfig}
        toastState={toastState}
        setToastState={setToastState}
      />
    </ToastContext.Provider>
  );
}

export const useToastConfig = () => {
  return useContext(ToastContext);
};

const Toast = ({ toastConfig, toastState, setToastState }: ToastStates) => {
  const timerRef = React.useRef(0);

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);
  return (
    <ToastPrimitive.Provider
      swipeDirection="right"
      duration={toastConfig?.duration || 5000}
    >
      <ToastPrimitive.Root
        open={toastState}
        onOpenChange={setToastState}
        className={`flex w-auto justify-center rounded-md py-2 pr-2 shadow-md ${
          toastTypes[toastConfig?.type || "success"]
        }`}
      >
        <ToastPrimitive.Description className="flex h-auto items-center justify-center gap-4 break-words px-4 text-center text-sm font-bold lg:break-normal">
          <div className="flex gap-5 ">
            <div className="h-6 w-6 self-center">
              {toastConfig?.type === "success" ? (
                <IconToastSuccess />
              ) : toastConfig?.type === "warning" ? (
                <IconWarning />
              ) : (
                <IconToastCross />
              )}
            </div>
            <span>{toastConfig?.description}</span>
          </div>
        </ToastPrimitive.Description>
        <ToastClose aria-label="Close">
          <IconCloseLg />
        </ToastClose>
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport className="lg:translate-none fixed top-20 left-1/2 z-[99] h-auto w-80 -translate-x-1/2 lg:left-auto lg:right-8 lg:w-auto lg:-translate-x-0" />
    </ToastPrimitive.Provider>
  );
};

export default Toast;
