import React from "react";
import * as Popover from "@radix-ui/react-popover";
import { IconInfo } from "ui";

const InfoTooltip = ({ description }: { description: string }) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button className="IconButton">
          <IconInfo className="mt-2 h-3.5 w-3.5" />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="w-60 lg:w-96 rounded-lg border border-brand-primary bg-white px-5 py-4 text-sm font-medium shadow-lg"
          sideOffset={1}
          side="top"
        >
          {description}
          <Popover.Arrow className="fill-brand-primary" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default InfoTooltip;
