import {
  SideMenuItemType,
  PersonOutline,
  IconCat,
  IconAddCircleOutline,
  IconDog,
} from "ui";
import { PET } from "./pet";
import { PROFILE } from "./profile";
import { ROUTES_PATH } from "./routes";

export const MENU_PATH = {
  ADD_PET: ROUTES_PATH.ADD_PET,
  PROFILE: ROUTES_PATH.PROFILE,
  PETS: ROUTES_PATH.PET_DASHBOARD,
};
export const SIDEMENU_ITEMS: Array<SideMenuItemType> = [
  {
    title: PROFILE.HEADING,
    path: MENU_PATH.PROFILE,
    icon: <PersonOutline className="h-6 w-6" />,
    classes: "",
  },
  {
    title: PET.HEADING,
    path: MENU_PATH.PETS,
    icon: (
      <span className="ml-[-40px] flex flex-row lg:ml-0">
        <IconDog className="h-6 w-6" />
        <IconCat className="h-6 w-6" />
      </span>
    ),
    classes: "ml-10 ",
  },
  {
    title: PET.ADD_PET,
    path: MENU_PATH.ADD_PET,
    icon: <IconAddCircleOutline className="h-6 w-6" />,
    classes: "",
  },
];
