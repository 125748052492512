import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Form, FormSpy } from "react-final-form";
import {
  calculateAge,
  calculateDateOfBday,
  useApi,
  prepareFormDataWithImage,
} from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  composeValidators,
  maxLength,
  required,
  mustBeNumber,
  Button,
  FormControl,
  dateGreaterThan,
  alphaNumericFieldsValidation,
  charValidationWithEmptyString,
} from "ui";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Area } from "react-easy-crop";

import BreedSelection from "./BreedSelection/BreedSelection";
import BreedSize from "./BreedSize/BreedSize";
import useRequireAuth from "../../../hooks/useRequireAuth";
import {
  MAX_CHAR_NAME,
  MESSAGES,
  PET,
  PET_BDAY_KNOWN,
} from "../../../constants";
import {
  BreedType,
  LifeStagesType,
  ModifierType,
  PetGender,
  PetTypes,
} from "../../../../types";

import { API_URLS } from "../../../constants/apiUrls";
import { useToastConfig } from "../../../lib/toast";
import {
  HideAndRemovePetApiOption,
  PetProfileKeyType,
  PetProfileType,
} from "../types";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { FormApi, MutableState, Tools } from "final-form";
import HideDeleteModals from "../HideDeleteModal";
import { Breadcrumb } from "../../../lib/breadcrumb";
import Heading from "../../Heading";
import { ADD_EDIT_PET } from "../../../constants/addEditPet";
import { BREADCRUMB_CONFIG, ROUTES_PATH } from "../../../constants/routes";
import PhotoUpload from "./PhotoUpload";
import PetProfilePhoto from "../PetProfilePhoto";

import { Datepicker } from "../../../lib/Datepicker";
import { LANG } from "../../../constants/languages";

export interface CustomizedState {
  state: { petId: string };
}

const AddEditPet = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // useState hooks
  const {
    lifeStages,
    petTypes,
    petGender,
    breeds,
    petStatusByName,
    petTypesByName,
  } = usePetConfig();
  const [age, setAge] = useState("");
  const [petName, setPetName] = useState("Pet");
  const [petNickName, setPetNickName] = useState("");
  const [petTypeId, setPetTypeId] = useState(1);
  const [hideDialogOpen, setHideDialogOpen] = useState(false);
  const [showReasonDialogDelete, setShowReasonDialogDelete] = useState(false);
  const [imageToUpload, setImageToUpload] = useState<{
    file: File;
    rendition: Area;
  }>();
  const [previousImage, setPreviousImage] = useState("");
  const [petImage, setPetImage] = useState("");
  const [petStatusId, setPetStatusId] = useState(null);
  const { showToast } = useToastConfig();
  const [loading, setLoading] = useState<{
    petId?: string;
    petStatusId?: number;
  }>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const location = useLocation();
  const petId = (location as CustomizedState)?.state?.petId;
  const [initData, setInitData] = useState<Record<string, unknown>>({
    petName: "",
    petNickName: "",
    petsAge: age,
    petLifeStage: {
      petLifeStageId: 0,
      petLifeStageName: "",
      petTypeId: "1",
      petAgeFrom: 0,
      petAgeTo: 0,
    },
    isPetBdayKnown: "yes",
    petBirthday: "",
    petAdoptDate: "",
    petsAgeMonth: 0,
    petBreed: "",
    petGenderId: "1",
    petSizeId: "",
    petTypeId: "1",
  });
  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (
      petId &&
      (lifeStages as Array<LifeStagesType>).length !== 0 &&
      (breeds as Array<BreedType>).length !== 0 &&
      (petTypes as Array<PetTypes>).length !== 0
    ) {
      fetchMyPetData();
    } else {
      setInitData({
        petName: "",
        petNickName: "",
        petsAge: age,
        petLifeStage: {
          petLifeStageId: 0,
          petLifeStageName: "",
          petTypeId: "1",
          petAgeFrom: 0,
          petAgeTo: 0,
        },
        isPetBdayKnown: "yes",
        petBirthday: "",
        petAdoptDate: "",
        petsAgeMonth: 0,
        petBreed: "",
        petGenderId: "1",
        petSizeId: "",
        petTypeId: "1",
      });
      setPetImage("");
      setPetNickName("");
    }

    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, [lifeStages, breeds, petTypes, location as CustomizedState]);

  // Pet age 25+ years warning message
  useEffect(() => {
    const splitAge = age.split(" ");
    (splitAge[1]?.toLowerCase() === "years" ||
      splitAge[1]?.toLowerCase() === "anos") &&
      parseInt(splitAge[0]) > 25 &&
      showToast({
        title: "AgeWarning",
        description: `${t(
          ADD_EDIT_PET.PET_AGE_MAX_WARNING_1
        )} ${petNickName}'s ${t(
          ADD_EDIT_PET.PET_AGE_MAX_WARNING_2
        )} ${age}. ${t(ADD_EDIT_PET.PET_AGE_MAX_WARNING_3)}?`,
        type: "warning",
      });
  }, [age]);

  // custom functions

  const isValueChanged = (isModifiedValues: ModifierType) => {
    return Object.keys(isModifiedValues);
  };

  const prepareObjToSend = (
    isModifiedValues: ModifierType,
    values: PetProfileType
  ) => {
    const petDataValues = {} as Partial<PetProfileType>;
    isValueChanged(isModifiedValues).forEach((key) => {
      const excludeValues = [
        "petsAge",
        "petsAgeMonth",
        "petsAgeYear",
        "isPetBdayKnown",
      ];
      const modifiedKey = key as keyof PetProfileKeyType;
      modifiedKey === "petLifeStage.petLifeStageName"
        ? (petDataValues["petLifeStageId"] = (
            values["petLifeStage"] as LifeStagesType
          ).petLifeStageId)
        : excludeValues.indexOf(modifiedKey) !== -1
        ? null
        : modifiedKey === "petBreed"
        ? (petDataValues["petBreedId"] = (values["petBreed"] as BreedType)
            .petBreedId as number)
        : modifiedKey === "petBirthday"
        ? (petDataValues["petBirthday"] = values["petBirthday"])
        : modifiedKey === "petAdoptDate"
        ? (petDataValues["petAdoptDate"] = values["petAdoptDate"])
        : modifiedKey === "petGenderId"
        ? (petDataValues["petGenderId"] = Number(values["petGenderId"]))
        : modifiedKey === "petTypeId"
        ? (petDataValues["petTypeId"] = Number(values["petTypeId"]))
        : modifiedKey === "petSizeId"
        ? (petDataValues["petSizeId"] = Number(values["petSizeId"]))
        : (petDataValues[modifiedKey] = values[modifiedKey]);
    });
    if (!petId && !petDataValues["petGenderId"])
      petDataValues["petGenderId"] = petGender[0].petGenderId;

    if (!petId && !petDataValues["petTypeId"])
      petDataValues["petTypeId"] = petTypesByName.Dog;

    petDataValues.petId = petId;
    return {
      petData: [petDataValues],
    };
  };

  const onSubmit = (
    values: PetProfileType,
    form: FormApi<PetProfileType, Partial<PetProfileType>>
  ) => {
    const updatedValues = prepareFormDataWithImage(
      prepareObjToSend(form.getState().dirtyFields, values),
      imageToUpload?.file,
      imageToUpload?.rendition
        ? {
            width: imageToUpload.rendition.width,
            height: imageToUpload.rendition.height,
            left: imageToUpload.rendition.x,
            top: imageToUpload.rendition.y,
          }
        : undefined
    );
    return petId ? updatePetApi(updatedValues) : addEditPet(updatedValues);
  };

  const filterLifeStage = (age: number, petTypeId: number) => {
    return lifeStages.find(
      (stage: LifeStagesType) =>
        stage.petTypeId === petTypeId &&
        age >= stage.petAgeFrom &&
        age <= stage.petAgeTo
    );
  };

  //Api configs
  // hide and delete pet api
  const { exec: fetchMyPetData } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}/${petId}`, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          const {
            petName,
            petNickName,
            petTypeId,
            petBreedId,
            petGenderId,
            petSizeId,
            petBirthday,
            petAdoptDate,
            petLifeStageId,
            petVetName,
            petImage,
            petStatusId,
          } = apiResponse.data.data[0];

          const petLifeStage = lifeStages.find(
            (stage: LifeStagesType) => stage.petLifeStageId === petLifeStageId
          );
          const petBreed = breeds.find(
            (breed: BreedType) => breed.petBreedId === petBreedId
          );
          setPetTypeId(petTypeId);
          setPetName(petName);
          setPetNickName(petNickName);
          setPetStatusId(petStatusId);
          const petAge = calculateAge(petBirthday, t);
          const response = {
            petName: petName,
            petNickName: petNickName,
            petsAge: petAge,
            petLifeStage: {
              petLifeStageId: petLifeStageId,
              petLifeStageName: t(`pet.${petLifeStage?.petLifeStageName}`),
              petTypeId: petLifeStage?.petTypeId,
              petAgeFrom: petLifeStage?.petAgeFrom,
              petAgeTo: petLifeStage?.petAgeTo,
            },
            isPetBdayKnown: "yes",
            petBirthday: petBirthday,
            petAdoptDate: petAdoptDate ? petAdoptDate : "",
            petsAgeMonth: 0,
            petBreed: petBreed,
            petGenderId: petGenderId?.toString(),
            petSizeId: petSizeId?.toString(),
            petTypeId: petTypeId?.toString(),
            petVetName,
          };
          setPetImage(petImage);
          setPreviousImage(petImage);
          setInitData(response);
        }
      });
  });

  const { exec: updatePetApi } = useApi((options: unknown) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .put(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, options)
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t(
              `addEditPet.${apiResponse.data.serviceStatus.message}`
            )}`,
            type: "success",
          });
          navigate(ROUTES_PATH.PET_DASHBOARD, { replace: true });
        } else {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });

  const { exec: addEditPet } = useApi((data: unknown) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .post(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, data, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          showToast({
            title: `${t(ADD_EDIT_PET.ADDED)}`,
            description: `${t(MESSAGES.PET_ADDED_MESSAGE)}`,
            type: "success",
          });
          window.scrollTo(0, 0);
          navigate(ROUTES_PATH.DASHBOARD, { replace: true });
        } else {
          //todo show error message
          showToast({
            title: `${t(ADD_EDIT_PET.REMOVED)}`,
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });
  //callback after  clicking  of  confirm  button
  const removeHidePet = (values: HideAndRemovePetApiOption[]) => {
    setLoading({
      petStatusId: values[0].petStatusId as number,
    });
    updatePetApi(prepareFormDataWithImage({ petData: values }));
  };

  const setProfilePhoto = (
    file?: File,
    croppedImage?: string,
    coordinates?: Area
  ) => {
    setPetImage(croppedImage ? croppedImage : previousImage);
    if (file && coordinates) {
      setImageToUpload({ file, rendition: coordinates });
    }
  };

  const petLifeStageMutator = (
    args: string,
    state: MutableState<PetProfileType, Partial<PetProfileType>>,
    utils: Tools<PetProfileType, Partial<PetProfileType>>
  ) => {
    utils.changeValue(state, "petLifeStage", () => {
      const age = calculateAge(args, t);
      setAge(calculateAge(args, t));
      let filteredLifeStage;
      const ageNumber = parseInt(age.split(" ")[0]);
      petTypes.every((type: PetTypes) => {
        if (Number(petTypeId) === type.petTypeId) {
          if (age.split(" ")[1] === "years" || age.split(" ")[1] === "Anos") {
            filteredLifeStage = filterLifeStage(ageNumber, type.petTypeId);
            return false;
          } else if (
            age.split(" ")[1] !== "years" &&
            age.split(" ")[1] !== "Anos"
          ) {
            filteredLifeStage = lifeStages.find(
              (stage: LifeStagesType) =>
                stage.petTypeId === type.petTypeId && stage.petAgeFrom === 0
            );
            return false;
          }
        }
        return true;
      });
      (filteredLifeStage as unknown as { petLifeStageName: string })[
        "petLifeStageName"
      ] = t(
        `pet.${
          (filteredLifeStage as unknown as { petLifeStageName: string })
            ?.petLifeStageName as string
        }`
      );
      return filteredLifeStage;
    });
  };

  const hideUnhidePet = (petId: string) =>{
    if(petStatusId === petStatusByName.Remembered){
      //means we are viewing remembered pets and this CTA event is for unhiding the pet.
      setLoading({
        petId:petId,
        petStatusId: petStatusByName.Remembered,
      });
      updatePetApi(
        prepareFormDataWithImage({
          petData: [
            {
              petId: petId,
              petStatusId: petStatusByName.Active,
            },
          ],
        })
      );
    }else{
      setHideDialogOpen(true);
    }
  }

  //auth check
  const auth = useRequireAuth();
  if (!auth || !auth?.user?.UID) return null;

  return (
    <>
      <Breadcrumb
        config={[
          BREADCRUMB_CONFIG.home,
          BREADCRUMB_CONFIG.petDashboard,
          petId
            ? { ...BREADCRUMB_CONFIG.pet, label: petNickName }
            : BREADCRUMB_CONFIG.addPet,
        ]}
      />
      <div className="mb-20">
        <Heading name={petId ? t(PET.HEADING) : t(PET.ADD_NEW_PET)} />
        <div className="rounded">
          <div>
            <div className="rounded-t-xl bg-brand-primary">
              <h2 className="py-4 pl-5 font-bold text-white capitalize">
                <>{petNickName ? petNickName : t("addEditPet.yourPet")}</>
              </h2>
            </div>
          </div>
          <div className="rounded-b-xl border border-brand-primary p-5">
            <Form
              initialValues={initData}
              onSubmit={onSubmit}
              mutators={{
                setFormAge: (args, state, utils) => {
                  utils.changeValue(state, "petsAge", () =>
                    calculateAge(args, t)
                  );
                  petLifeStageMutator(args, state, utils);
                },
                setFormDOB: (args, state, utils) => {
                  utils.changeValue(state, "petBirthday", () =>
                    calculateDateOfBday(args[0], args[1])
                  );
                  utils.changeValue(state, "petsAge", () =>
                    calculateAge(calculateDateOfBday(args[0], args[1]), t)
                  );
                  petLifeStageMutator(
                    calculateDateOfBday(args[0], args[1]),
                    state,
                    utils
                  );
                },
                setPetSize: (args, state, utils) => {
                  utils.changeValue(state, "petSizeId", () =>
                    args?.[0]?.petSizeId.toString()
                  );
                },
              }}
              render={({
                handleSubmit,
                form,
                values,
                submitting,
                pristine,
                valid,
              }) => (
                <form onSubmit={handleSubmit} role="form">
                  <FormSpy
                    onChange={(props) => {
                      if (props.active === "petTypeId") {
                        setPetTypeId(props.values.petTypeId);
                        props.values.petsAge = "";
                        props.values.petLifeStage = {};
                        props.values.petBirthday = "";
                        props.values.petBreed = null;
                        props.values.petSizeId = '';
                        setAge("");
                      }
                    }}
                  />
                  
                  <div className="gap-6 lg:flex">
                    <div className="flex-none lg:w-1/3">
                      <div className="w-full flex-col items-center gap-5">
                        <>
                          <PetProfilePhoto
                            src={petImage}
                            size="md"
                            rounded="2xl"
                            petName={values.petName}
                            classes="mx-auto w-48 md:w-80"
                          />
                          <PhotoUpload
                            setProfilePhoto={setProfilePhoto}
                            isEdit={!!petImage}
                          />
                          {petId && (
                            <div className="flex justify-center">
                              <div className="lg:align-center mt-2 lg:w-2/3 lg:border-t-2"></div>
                            </div>
                          )}
                          {
                            <div className="mt-5 flex flex-col items-center">
                              <div>
                                <>
                                  <span className="text-sm font-bold mr-2">
                                    {t(ADD_EDIT_PET.BREED)}
                                  </span>
                                  {initData.petBreed ?
                                    (initData.petBreed as BreedType)
                                      .petBreedName : t(ADD_EDIT_PET.NOT_SELECTED_TEXT)}
                                </>
                              </div>
                              <div className="mt-2">
                                <>
                                  <span className="text-sm font-bold mr-2">
                                    {t(ADD_EDIT_PET.AGE)}
                                  </span>
                                  {initData.petsAge ? initData.petsAge : t(ADD_EDIT_PET.NOT_SELECTED_TEXT)}
                                </>
                              </div>
                            </div>
                          }
                        </>
                      </div>
                    </div>
                    <div className="mt-10 lg:mt-0 lg:grow">
                      <FormControl
                        fieldProps={{
                          name: "petName",
                          component: "input",
                          validate: composeValidators(
                            required(t("validations.isRequired") as string),
                            alphaNumericFieldsValidation(
                              `${t("validations.noSpecialCharsNums")}`
                            ),
                            maxLength(
                              MAX_CHAR_NAME,
                              `${t("validations.canOnlyBe")}`,
                              `${t("validations.chars")}`,
                              `${t("validations.digits")}`,
                              `${t("validations.long")}`
                            )
                          ),
                        }}
                        onBlurCapture={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.value
                            ? setPetName(e.target.value)
                            : setPetName("Pet")
                        }
                        placeholder={`${t(ADD_EDIT_PET.PETS_NAME)}`}
                        label={`${t(ADD_EDIT_PET.PETS_NAME)}`}
                        isRequired={true}
                        classes="h-24"
                      />

                      <FormControl
                        fieldProps={{
                          name: "petNickName",
                          component: "input",
                          validate: composeValidators(
                            required(t("validations.isRequired") as string),
                            alphaNumericFieldsValidation(
                              `${t("validations.noSpecialCharsNums")}`
                            ),
                            maxLength(
                              MAX_CHAR_NAME,
                              `${t("validations.canOnlyBe")}`,
                              `${t("validations.chars")}`,
                              `${t("validations.digits")}`,
                              `${t("validations.long")}`
                            )
                          ),
                        }}
                        onBlurCapture={(e: ChangeEvent<HTMLInputElement>) =>
                          e.target.value && setPetNickName(e.target.value)
                        }
                        isRequired
                        placeholder={`${t(ADD_EDIT_PET.PETS_NICKNAME)}`}
                        label={`${t(ADD_EDIT_PET.PETS_NICKNAME)}`}
                        classes="h-24"
                      />

                      <div className="mt-5 mb-5">
                        <label
                          htmlFor="petType"
                          className="self-baseline text-xl font-bold"
                        >
                          {t(ADD_EDIT_PET.WHAT_KIND_OF_PET_DO_YOU_HAVE)}?
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="gap-10 lg:flex">
                          {petTypes.map((type: PetTypes) => {
                            return (
                              <FormControl
                                key={`${type.petTypeId}_type`}
                                fieldProps={{
                                  name: "petTypeId",
                                  component: "input",
                                  value: type.petTypeId.toString(),
                                }}
                                type="radio"
                                classes="-ml-10 w-fit"
                              >
                                {t(`addEditPet.${type.petTypeName}`)}
                              </FormControl>
                            );
                          })}
                        </div>
                      </div>

                      <div className="mt-2 mb-2">
                        <label className="self-baseline text-xl font-bold">
                          {t(ADD_EDIT_PET.PETS_GENDER)}
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="gap-10 lg:flex">
                          {petGender.map((type: PetGender) => {
                            return (
                              <FormControl
                                key={type.petGenderId}
                                fieldProps={{
                                  name: "petGenderId",
                                  component: "input",
                                  value: type.petGenderId.toString(),
                                }}
                                type="radio"
                                classes="-ml-10 w-fit"
                              >
                                {t(`addEditPet.${type.petGenderName}`)}
                              </FormControl>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <BreedSelection
                    setPetSize={(value) => form.mutators.setPetSize(value)}
                    petTypeId={petTypeId}
                  />
                  <BreedSize petTypeId={petTypeId} />
                  {values.isPetBdayKnown ? (
                    <div
                      className={`gap-2 lg:flex ${
                        values.isPetBdayKnown === PET_BDAY_KNOWN.NO
                          ? "flex-row-reverse"
                          : "row"
                      }`}
                    >
                      <Datepicker
                        name="petBirthday"
                        label={`${t(ADD_EDIT_PET.PETS_BIRTHDAY)}`}
                        validate={required(
                          t("validations.isRequired") as string
                        )}
                        disabled={values.isPetBdayKnown === PET_BDAY_KNOWN.NO}
                        max={new Date()}
                        onBlurCapture={(e: {
                          target: { name: string; value: string };
                        }) => {
                          values.isPetBdayKnown === PET_BDAY_KNOWN.YES &&
                            form.mutators.setFormAge(e.target.value);
                        }}
                      />

                      {values.isPetBdayKnown === PET_BDAY_KNOWN.YES ? (
                        <FormControl
                          fieldProps={{
                            name: "petsAge",
                            component: "input",
                          }}
                          classes="hidden"
                          disabled={
                            values.isPetBdayKnown === PET_BDAY_KNOWN.YES
                          }
                          label={`${t(ADD_EDIT_PET.PETS_AGE)}`}
                        />
                      ) : (
                        <div className="gap-2 lg:flex">
                          <FormControl
                            fieldProps={{
                              name: "petsAgeYear",
                              component: "input",
                              validate: mustBeNumber(
                                `${t("validations.mustBeNumber")}`
                              ),
                            }}
                            onBlurCapture={() => {
                              values.isPetBdayKnown === PET_BDAY_KNOWN.NO &&
                                form.mutators.setFormDOB(
                                  values.petsAgeYear,
                                  values.petsAgeMonth
                                );
                            }}
                            placeholder={`${t(ADD_EDIT_PET.YEAR)}`}
                            label={`${t(ADD_EDIT_PET.PETS_AGE_YEAR)}`}
                            classes="-mb-2"
                          />
                          <FormControl
                            fieldProps={{
                              name: "petsAgeMonth",
                              component: "input",
                              validate: mustBeNumber(
                                `${t("validations.mustBeNumber")}`
                              ),
                            }}
                            onBlurCapture={() => {
                              values.isPetBdayKnown === PET_BDAY_KNOWN.NO &&
                                form.mutators.setFormDOB(
                                  values.petsAgeYear,
                                  values.petsAgeMonth
                                );
                            }}
                            placeholder={`${t(ADD_EDIT_PET.PET)}`}
                            label={`${t(ADD_EDIT_PET.PETS_AGE_MONTH)}`}
                            classes="-mb-2"
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div className="mt-5 mb-5 lg:flex">
                    <FormControl
                      fieldProps={{
                        name: "isPetBdayKnown",
                        component: "input",
                        value: PET_BDAY_KNOWN.YES,
                      }}
                      type="radio"
                      classes="-mt-5 w-fit -ml-10"
                    >
                      {t(ADD_EDIT_PET.ACTUAL_DATE)}
                    </FormControl>
                    <FormControl
                      fieldProps={{
                        name: "isPetBdayKnown",
                        component: "input",
                        value: PET_BDAY_KNOWN.NO,
                      }}
                      type="radio"
                      classes="mt-5 w-fit -ml-10 lg:ml-5 lg:-mt-5"
                    >
                      {t(ADD_EDIT_PET.APPROXIMATE_DATE)}
                    </FormControl>
                  </div>

                  <Datepicker
                    name="petAdoptDate"
                    validate={dateGreaterThan(
                      values.petBirthday,
                      `${t("validations.greater")}${t(
                        ADD_EDIT_PET.PETS_BIRTHDAY
                      )}`
                    )}
                    max={new Date()}
                    label={`${t(ADD_EDIT_PET.PETS_ADOPTION_DATE)}`}
                    optionalText={`${t(ADD_EDIT_PET.OPTIONAL_TEXT)}`}
                  />
                  <FormControl
                    fieldProps={{
                      name: "petLifeStage.petLifeStageName",
                      component: "input",
                      validate: required(
                        t("validations.petsLifeStage") as string
                      ),
                    }}
                    errorShort={true}
                    disabled={true}
                    label={`${t(ADD_EDIT_PET.PETS_LIFE_STAGE)}`}
                    value={values.petLifeStage.petLifeStageName}
                  />

                  <FormControl
                    data-testid="Pet's Vet"
                    fieldProps={{
                      name: "petVetName",
                      component: "input",
                      validate: composeValidators(
                        charValidationWithEmptyString(
                          `${t("validations.noSpecialCharsNums")}`
                        ),
                        maxLength(
                          MAX_CHAR_NAME,
                          `${t("validations.canOnlyBe")}`,
                          `${t("validations.chars")}`,
                          `${t("validations.digits")}`,
                          `${t("validations.long")}`
                        )
                      ),
                    }}
                    placeholder={
                      petName
                        ? `${
                            auth.locale === LANG.EN
                              ? `${petName}${t(ADD_EDIT_PET.S_VET)}`
                              : `${t(ADD_EDIT_PET.S_VET)} ${petName}`
                          }`
                        : `${t(ADD_EDIT_PET.PETS_VET)}`
                    }
                    label={
                      petName
                        ? `${
                            auth.locale === LANG.EN
                              ? `${petName}${t(ADD_EDIT_PET.S_VET)}`
                              : `${t(ADD_EDIT_PET.S_VET)} ${petName}`
                          }`
                        : `${t(ADD_EDIT_PET.PETS_VET)}`
                    }
                    optionalText={`${t(ADD_EDIT_PET.OPTIONAL_TEXT)}`}
                  />
                  {!valid && !pristine && (
                    <div className="flex justify-center text-brand-error">
                      <>{t(ADD_EDIT_PET.COMMON_ERROR_WARNING)}</>
                    </div>
                  )}

                  <div className="mt-10 flex-row justify-center lg:flex lg:items-end lg:justify-end lg:space-x-5">
                    {petId && (
                      <>
                        <Button
                          mode="link"
                          variant="primary"
                          aria-label="remove pet"
                          type="button"
                          isLoading={
                            loading &&
                            loading.petStatusId === petStatusByName.Deleted 
                          }
                          onClick={() => {
                            setShowReasonDialogDelete(true);
                          }}
                          classes="flex-col w-full mb-5 lg:mb-0 items-center lg:w-auto lg:flex-none"
                        >
                          {t(ADD_EDIT_PET.REMOVE_PET)}
                        </Button>
                        <Button
                          mode="link"
                          variant="primary"
                          aria-label={petStatusId === petStatusByName.Remembered ? t(ADD_EDIT_PET.UNHIDE_PET): t(ADD_EDIT_PET.HIDE_PET)}
                          type="button"
                          isLoading={
                            loading &&
                            loading.petStatusId === petStatusByName.Remembered
                          }
                          onClick={() => {
                            hideUnhidePet(petId);
                          }}
                          classes="flex-col mb-5 lg:mb-0 w-full items-center lg:w-auto lg:flex-none"
                        >
                          {petStatusId === petStatusByName.Remembered ? t(ADD_EDIT_PET.UNHIDE_PET): t(ADD_EDIT_PET.HIDE_PET)}
                        </Button>
                      </>
                    )}
                    <Button
                      variant="primary"
                      aria-label="submit"
                      type="submit"
                      classes="flex-col w-full items-center mb-5 lg:mb-0 lg:w-auto lg:flex-none"
                      disabled={(pristine && !imageToUpload) || submitting}
                      isLoading={submitting}
                    >
                      {petId
                        ? `${t(ADD_EDIT_PET.UPDATE)}`
                        : `${t(ADD_EDIT_PET.SAVE)}`}
                    </Button>
                    <Button
                      variant="default"
                      aria-label="discard changes"
                      type="button"
                      disabled={(pristine && !imageToUpload) || submitting}
                      onClick={() => {
                        setPetName(initData.petName as string);
                        setPetNickName(initData.petNickName as string);
                        setProfilePhoto(undefined);
                        form.reset();
                      }}
                      classes="flex-col w-full items-center mb-5 lg:mb-0 lg:w-auto lg:flex-none"
                    >
                      {t(ADD_EDIT_PET.DISCARD_CHANGES)}
                    </Button>
                  </div>
                </form>
              )}
            />
            <HideDeleteModals
              hideAndRemovePetApi={removeHidePet}
              petId={(location as CustomizedState).state?.petId}
              showReasonDialogDelete={showReasonDialogDelete}
              setShowReasonDialogDelete={setShowReasonDialogDelete}
              setHideDialogOpen={setHideDialogOpen}
              hideDialogOpen={hideDialogOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditPet;
