import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button, FormControl } from "ui";
import { MESSAGES } from "../../../constants";
import { usePetConfig } from "../../../hooks/usePetConfig";

const DeletePetOptionsForm = ({
  setReasonForDeleting,
  setShowReasonDialogDelete,
  setConfirmationDialogOpen,
}: {
  setReasonForDeleting: (val: string) => void;
  setShowReasonDialogDelete: (val: boolean) => void;
  setConfirmationDialogOpen: (val: boolean) => void;
}) => {
  const { petRemovalReasons } = usePetConfig();
  const { t } = useTranslation();
  const onSubmit = (e: { reason: string }) => {
    setShowReasonDialogDelete(false);
    setConfirmationDialogOpen(true);
    setReasonForDeleting(e.reason);
  };
  return (
    <div className="flex items-center">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <div className="flex w-auto justify-center">
              <form id="changePasswordForm" onSubmit={handleSubmit} role="form">
                <div className="flex w-96 flex-col">
                  <div className="flex px-4 text-center text-lg lg:px-0">
                    {t(MESSAGES.PET_REMOVAL_REASON_DESC)}
                  </div>
                  <div className="mt-4 flex justify-center text-lg font-bold">
                    {t(MESSAGES.CHOOSE_A_REASON_FOR_REMOVAL)}
                  </div>
                  <div className="mt-2 flex flex-col items-center">
                    {petRemovalReasons.map(
                      (reason: { id: number; name: string }) => {
                        return (
                          <>
                            <FormControl
                              key={reason.id}
                              fieldProps={{
                                name: "reason",
                                component: "input",
                                value: reason.name,
                              }}
                              type="radio"
                              classes="-mb-2 w-64 ml-6"
                            >
                              {t(`addEditPet.${reason.name}`)}
                            </FormControl>
                          </>
                        );
                      }
                    )}
                  </div>
                  <div className="mt-5 flex flex-col items-center">
                    <Button
                      type="submit"
                      mode="contained"
                      disabled={submitting || pristine}
                      variant="primary"
                      isLoading={submitting}
                      classes="mt-10 lg:mt-2 w-80 py-4 font-bold text-xl px-4"
                    >
                      {t(MESSAGES.PET_REMOVAL_SAVE_LABEL)}
                    </Button>

                    <Button
                      mode="link"
                      variant="primary"
                      aria-label="cancel button"
                      type="button"
                      onClick={() => {
                        setShowReasonDialogDelete(false);
                      }}
                      classes="flex-col mb-5 lg:mb-0 items-center lg:w-auto lg:flex-none mt-2"
                    >
                      {t(MESSAGES.PET_REMOVAL_CANCEL_LABEL)}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          );
        }}
      />
    </div>
  );
};

export default DeletePetOptionsForm;
