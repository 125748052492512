import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationBR from "./locales/pt-br/translation.json";
import { ILANG, LANG } from "./constants/languages";

/**
 * function to get locale code from domain url
 * @returns language
 */
export function getLangFromURL () {
  const urlArr = window.location.href?.split(".");
  const lang =
    urlArr[urlArr.length - 1]?.split("/")[0] === "com"
      ? LANG.EN
      : urlArr[urlArr.length - 1]?.split("/")[0];

  return LANG[lang.toUpperCase() as keyof ILANG] || LANG.BR;
}
/**
 * function to get locale code from environment variables
 * @returns language
 */
export function getLangFromEnvVariables () {
  return process.env.REACT_APP_LANGUAGE as string;
}
// resources to use for translations
const resources = {
  en: {
    translation: translationEN,
  },
  "pt-BR": {
    translation: translationBR,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use({
    type: "languageDetector",
    async: false,
    // change this to function that return value process.env.REACT_APP_LANGUAGE when for HPH-1030 when Justin did his work
    detect: getLangFromEnvVariables,
    cacheUserLanguage: function () {
      return;
    },
  })
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: LANG.EN,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
