import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ImageAvatar, IconEditPhoto, IconDefaultProfilePic } from "ui";
import {
  hasAllowedExt,
  hasAllowedSize,
  hasDoubleExtInFileName,
  useApi,
} from "utils";
import { ALLOWED_IMAGE_EXT, MESSAGES } from "../../../constants";
import { ADD_EDIT_PET } from "../../../constants/addEditPet";
import { API_URLS } from "../../../constants/apiUrls";
import { useToastConfig } from "../../../lib/toast";
import PhotoCropper from "../../Cropper";
import { Area } from "react-easy-crop";
import { Auth, useAuth } from "../../../hooks/use-auth";

export default function ProfilePhotoUpload({
  photoUrl,
}: {
  photoUrl: string | undefined;
}) {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [profilePic, setProfilePic] = useState<string | undefined>(photoUrl);
  const auth: Auth = useAuth();
  const [image, setImage] = useState<File>();
  const [openCropper, setOpenCropper] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  function handleImageUpload(file: File) {
    setImage(file);
    setOpenCropper(true);
  }

  function handleCroppedImageUpload(croppedImage: string, croppedArea?: Area) {
    if (croppedImage && croppedArea) {
      const formData = new FormData();
      formData.append("image", image as Blob);
      formData.append(
        "rendition",
        JSON.stringify({
          width: croppedArea.width,
          height: croppedArea.height,
          left: croppedArea.x,
          top: croppedArea.y,
        })
      );
      formData.append("data", JSON.stringify({ profile: {} }));
      updateMyProfileData(formData);
    }
    setProfilePic(croppedImage);
    auth?.setUser &&
      auth.setUser((prev) => ({
        ...prev,
        profile: { ...prev.profile, photoUrl: croppedImage },
      }));
    setOpenCropper(false);
  }

  useEffect(() => {
    setProfilePic(photoUrl);
  }, [photoUrl]);

  const { exec: updateMyProfileData } = useApi((data) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .post(
        `${process.env.REACT_APP_PROFILE_API_URL}${API_URLS.PARENT_PROFILE}`,
        data,
        {
          signal: abortController.signal,
        }
      )
      .then((apiResponse) => {
        if (
          apiResponse.status === 200 &&
          apiResponse.data.serviceStatus.code === 200
        ) {
          // Update message here after updating profile image
          showToast({
            title: "Added",
            description: `${t(MESSAGES.PROFILE_PIC_UPDATE_MESSAGE)}`,
            type: "success",
          });
        } else {
          // show error message
          showToast({
            title: "Error",
            description: `${t("common.updateFailed")}`,
            type: "error",
          });
        }
      });
  });
  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);
  // after photo selection
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const MAX_SIZE = process.env.REACT_APP_PROFILE_PIC_SIZE_IN_MB as string;

    if (files) {
      const isAllowedExt = hasAllowedExt(ALLOWED_IMAGE_EXT, files[0].type);
      const isAllowedSize = hasAllowedSize(+MAX_SIZE, files[0].size);
      const hasDoubleExt = hasDoubleExtInFileName(files[0].name);

      if (isAllowedExt && isAllowedSize && !hasDoubleExt) {
        handleImageUpload(files[0]);
      } else {
        showToast({
          title: ADD_EDIT_PET.ADDED,
          description: !isAllowedExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : hasDoubleExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : `${t(MESSAGES.PROFILE_PIC_SIZE_ERROR)}` + MAX_SIZE + "mb",
          type: "error",
        });
      }
      e.target.value = "";
    }
  };

  return (
    <>
      {" "}
      <label
        htmlFor="profile-pic-upload"
        className="relative flex cursor-pointer flex-col items-center justify-center"
        data-testid="imageUpload"
        role="img"
      >
        <span className="sr-only">avatar</span>
        <ImageAvatar
          size="sm"
          src={profilePic}
          alt={`Your Profile Photo`}
          defaultAvatar={<IconDefaultProfilePic className="h-40 w-40" />}
          rounded="full"
          classes="w-40 max-w-none"
          background="bg-white"
        />
        <IconEditPhoto
          className="absolute top-3/4 right-[5%] h-12 w-12"
          data-testid="edit-icon"
        />
        <input
          id="profile-pic-upload"
          aria-label="profile pic upload"
          type="file"
          accept={ALLOWED_IMAGE_EXT.join(",")}
          className="sr-only"
          onChange={handleFileChange}
          role="img"
          data-testid="profile-pic-upload"
        />
      </label>
      <PhotoCropper
        open={openCropper}
        setOpen={setOpenCropper}
        image={image ? URL.createObjectURL(image as Blob) : ""}
        aspect={1}
        cropShape="round"
        setCroppedImage={handleCroppedImageUpload}
        title={photoUrl ? t("common.changePhoto") : t("common.uploadPhoto")}
      />
    </>
  );
}
