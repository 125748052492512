import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import type { SideMenuItemType } from "ui";

interface CustomNavLinkProps {
  linkItem: SideMenuItemType;
  iconOnly?: boolean;
}

export const CustomNavLink = ({
  linkItem,
  iconOnly = false,
}: CustomNavLinkProps) => {
  const { title, path, icon } = linkItem;
  const { t } = useTranslation();
  return (
    <NavLink
      to={path}
      aria-label={t(title) as string}
      className={({ isActive }) =>
        isActive ? "text-brand-accent-default" : ""
      }
    >
      <div className="flex cursor-pointer items-center gap-3 text-lg font-semibold text-white">
        {icon}
        {!iconOnly && <span>{t(title)}</span>}
        <span className="hidden">Sidebar navlinks</span>
      </div>
    </NavLink>
  );
};
