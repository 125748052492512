import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Add } from "ui";
import { PET, ROUTES_PATH } from "../../../constants";

export default function AddPetTile() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card
      classes="flex-col w-full justify-center items-center cursor-pointer border border-brand-secondary hover:drop-shadow-2xl"
      onClick={() => navigate(ROUTES_PATH.ADD_PET, { replace: false })}
    >
      <Add width={59} height={60} />
      <Button aria-label={t(PET.ADD_PET)} mode="link" size="lg" variant="dark">
        {t(PET.ADD_PET)}
      </Button>
    </Card>
  );
}
