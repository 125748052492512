import axios from "axios";
import gigya from "../gigya";

const API_BAD_REQUEST = 403;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status === API_BAD_REQUEST) {
      gigya.accounts.logout();
    }
    return error;
  }
);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("gigyaToken");
  if (token) {
    config.headers = config.headers ? config.headers : {}; 
    config.headers.Authorization = 'Bearer ' +  (localStorage.getItem("gigyaToken") as string);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const api = axios.create({
  baseURL: '',
  timeout: 3000,
  headers: { 'X-Custom-Header': 'foobar' },
});

export type APICallStatus = "IDLE" | "PENDING" | "SUCCESS" | "ERROR";
