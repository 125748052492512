import React from "react";

export default function Heading({
  name,
  classes = "",
}: {
  name: string | React.ReactNode;
  classes?: string;
}) {
  return (
    <h1
      className={`mb-7 text-2xl font-extrabold md:text-3xl lg:mb-9 ${classes}`}
    >
      {name}
    </h1>
  );
}
