declare global {
  interface Window {
    dataLayer: Array<DataLayerEvent>;
  }
}

interface DataLayerEvent {
  event: string;
  user_type: string;
  method: string;
  action: string;
}

export function datalayerPush(dataLayer: DataLayerEvent) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayer);
}
