import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui";
import { MESSAGES } from "../../../constants";

const DeletePetConfirmOptionsForm = ({
  setConfirmationDialogOpen,
  deletePermanently,
}: {
  setConfirmationDialogOpen: (val: boolean) => void;
  deletePermanently: () => void;
}) => {
  const { t } = useTranslation();
  const onSubmit = () => {
    deletePermanently();
  };
  return (
    <div className="flex items-center">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <div className="flex w-auto justify-center">
              <form id="changePasswordForm" onSubmit={handleSubmit} role="form">
                <div className="flex w-96 flex-col">
                  <div className="flex flex-col px-4 text-center text-lg lg:px-0">
                    <div>{t(MESSAGES.PET_REMOVING_PERMANENT_DESC)}</div>
                    <div>{t(MESSAGES.PET_REMOVING_PERMANENT_DESC_SEC)}</div>
                  </div>
                  <div className="mt-20 flex flex-col items-center">
                    <Button
                      type="submit"
                      mode="contained"
                      variant="primary"
                      isLoading={submitting}
                      classes="mt-10 lg:mt-2 w-80 py-4 font-bold text-xl px-4"
                    >
                      {t(MESSAGES.PET_REMOVAL_CONFIRMATION_SAVE_LABEL)}
                    </Button>

                    <Button
                      mode="link"
                      variant="primary"
                      aria-label="cancel button"
                      type="button"
                      onClick={() => {
                        setConfirmationDialogOpen(false);
                      }}
                      classes="flex-col mb-5 lg:mb-0 items-center lg:w-auto lg:flex-none mt-2"
                    >
                      {t(MESSAGES.PET_REMOVAL_CANCEL_LABEL)}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          );
        }}
      />
    </div>
  );
};

export default DeletePetConfirmOptionsForm;
