import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, ButtonWide, Loader, IconAddOutlineBlue } from "ui";
import { useApi } from "utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { API_URLS } from "../../../constants/apiUrls";
import { PetType } from "../../../../types";
import Heading from "../../Heading";
import { Breadcrumb } from "../../../lib/breadcrumb";
import { BREADCRUMB_CONFIG, ROUTES_PATH, PET } from "../../../constants";
import PetDetails, { PetName } from "./PetDetails";
import HiddenPets from "./HiddenPets";
import { RecommendedArticles } from "../../RecommendedArticles";

export default function PetDashBoard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [hiddenPets, setHiddenPets] = useState<PetType[]>([]);
  const [petList, setPetList] = useState<PetType[]>([]);
  const [isDataloading, setIsDataloading] = useState(true);
  const [openIndex, setOpenIndex] = useState(0);

  //Api configs
  //fetch pet Api
  const { exec: fetchPetListData } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          const activePets: PetType[] = [];
          const inactivePets: PetType[] = [];

          apiResponse.data.data.forEach((pet: PetType) => {
            pet.petStatusId === 2
              ? inactivePets.push(pet)
              : activePets.push(pet);
          }); // 2 = inactive
          setHiddenPets(inactivePets);
          setPetList(activePets);
        }
        setIsDataloading(false);
      });
  });

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    fetchPetListData();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  return (
    <>
      <Breadcrumb
        config={[BREADCRUMB_CONFIG.home, BREADCRUMB_CONFIG.petDashboard]}
      />
      <Heading name={t(PET.HEADING)} />
      {isDataloading ? (
        <Loader />
      ) : (
        <>
          <Accordion
            name="activePet"
            variant="primary"
            items={petList}
            header={(item: PetType) => (
              <PetName name={item.petNickName || item.petName} />
            )}
            content={(item: PetType) => <PetDetails data={item} />}
            openIndex={openIndex}
            updateOpenIndex={setOpenIndex}
          />

          <ButtonWide
            aria-label={t(PET.ADD_PET)}
            mode="outlined"
            size="md"
            variant="primary"
            classes="hover:bg-white hover:text-brand-primary"
            onClick={() => navigate(ROUTES_PATH.ADD_PET, { replace: false })}
          >
            <IconAddOutlineBlue className="mr-3  h-5 w-5 " /> {t(PET.ADD_PET)}
          </ButtonWide>
        </>
      )}
      <HiddenPets hiddenPets={hiddenPets} />
      <RecommendedArticles />
    </>
  );
}
