export const PROFILE = {
  HEADING: "profile.yourProfile",
  CHANGE_PHOTO: "Edit Image",
  PASSWORD_CHANGE: "Edit",
  MANAGE_PREFERENCES: "Manage Preferences",
  SAVE: "Save",
  LABEL: {
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email Address",
    PHONE: "Phone Number",
  },
  HEADINGS: {
    NAME: "Name",
    EMAIL: "Email",
    PHONE: "Phone",
    PASSWORD: "Password",
  },
};
