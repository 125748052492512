import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconExpandDown, Loader } from "ui";
import { useApi } from "utils";
import axios from "axios";
import { API_URLS } from "../../../constants/apiUrls";
import { PET_LIST_PETS_INITIALLY_VISIBLE, PET } from "../../../constants";
import { PetType } from "../../../../types";
import { PetTile, AddPetTile } from "../PetTile";
import Heading from "../../Heading";
import { Auth, useAuth } from "../../../hooks/use-auth";

const PetPotalName = () => {
  const auth: Auth = useAuth();
  const { t } = useTranslation();
  return (
    <>{`${t(`addEditPet.petPortal`)} ${
      auth?.user?.profile?.firstName ?? ""
    }`}</>
  );
};
const NoPet = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-3xl font-extrabold">
        <PetPotalName />
      </h1>
      <p className="mb-15 mt-6 text-lg font-normal lg:w-2/3">
        {`${t("noPet.warningOne")} “${t(PET.ADD_PET).toLowerCase()}
        ” ${t("noPet.warningTwo")}`}
      </p>
    </>
  );
};

export default function PetList() {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [wholePetList, setWholePetList] = useState<PetType[]>([]);
  const [petList, setPetList] = useState<PetType[]>([]);
  const [showMore, setShowMore] = useState(true);
  const [isDataloading, setIsDataloading] = useState(true);
  const { t } = useTranslation();

  //Api configs
  //fetch pet Api
  const { exec: fetchPetListData } = useApi(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`, {
        signal: abortController.signal,
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          const filteredList = apiResponse.data.data.filter(
            (pet: PetType) => pet.petStatusId !== 2
          );
          setWholePetList(filteredList);
          setShowMore(filteredList.length > PET_LIST_PETS_INITIALLY_VISIBLE);
          setPetList(filteredList.slice(0, PET_LIST_PETS_INITIALLY_VISIBLE));
        } else {
          setShowMore(false);
        }
        setIsDataloading(false);
      });
  });

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    fetchPetListData();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  function showMorePets() {
    setShowMore(!showMore);
    setPetList(wholePetList);
  }
  return (
    <div className="py-0 lg:py-8">
      {isDataloading ? (
        <Loader />
      ) : (
        <>
          {petList.length ? (
            <Heading name={<PetPotalName />} classes="hidden md:block" />
          ) : (
            <NoPet />
          )}
          <div className="flex flex-col">
            <div
              className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3"
              role="list"
              aria-label="pets"
            >
              <>
                {petList &&
                  petList.map((pet: PetType) => (
                    <PetTile pet={pet} key={pet.petId} />
                  ))}
              </>
              {!showMore && <AddPetTile />}
            </div>
            {showMore && (
              <Button
                variant="primary"
                mode="link"
                classes="self-end flex items-center"
                onClick={showMorePets}
              >
                <IconExpandDown width="24" height="24" />{" "}
                <span className="ml-5">
                  {t("common.view")}{" "}
                  {showMore ? t("common.more") : t("common.less")}
                </span>
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
