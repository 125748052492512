import { Card, DefaultPetProfile, ImageAvatar } from "ui";

import { Article } from "../../../types";

const ArticleTile = ({ article }: { article: Article }) => {
  return (
    <Card
      role="listitem"
      classes="min-h-fit lg:min-h-full flex-col w-full relative pointer"
      aria-label={article.title}
    >
      <a
        href={article.url}
        target="_blank"
        className="text-base font-bold text-brand-accent-gray-medium no-underline md:text-xl"
        data-testid="article"
        title={article.title}
      >
        <ImageAvatar
          src={article.image}
          size="md"
          rounded="none"
          alt={article.title + " image"}
          defaultAvatar={<DefaultPetProfile className="h-28 w-full md:h-44" />}
          background="bg-brand-accent-gray-light h-28 md:h-44"
          classes="w-full"
        />

        <h3 className="mt-2.5 w-5/6 text-base font-bold text-brand-accent-gray-medium md:w-4/5 md:text-xl ">
          {article.title}
        </h3>
      </a>
    </Card>
  );
};

export default ArticleTile;
