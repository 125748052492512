import React, { ReactNode } from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { Button, IconClose } from "ui";

export const AlertDialog = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogContent = AlertDialogPrimitive.Content;
export const AlertDialogAction = AlertDialogPrimitive.Action;
export const AlertDialogCancel = AlertDialogPrimitive.Cancel;

interface BtnConfig {
  name: string;
  callback?: () => void;
  variant?: string;
  mode?: string;
  size?: string;
  type?: string;
  classes?: string;
}
interface ModalConfig {
  modalConfig: {
    title?: string;
    btnConfig: BtnConfig[];
    body?: string | ReactNode;
    titleWidth?: string;
  };
  dialogState: boolean;
  setDialog: (open: boolean) => void;
}

export const Modal = ({ modalConfig, dialogState, setDialog }: ModalConfig) => {
  return (
    <>
      <AlertDialog open={dialogState}>
        <AlertDialogContent>
          <>
            <div className="fixed inset-0 z-[999] flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
              <div className="fixed inset-0 h-full w-full bg-black opacity-40"></div>
              <div className="relative my-0 mx-auto w-auto lg:my-6">
                <div className="relative flex h-screen w-full  flex-col border-0 bg-white shadow-lg outline-none focus:outline-none lg:h-fit">
                  <div
                    className="float-right flex justify-end border-0 bg-transparent text-black"
                    onClick={() => setDialog(false)}
                  >
                    <div
                      className="mt-5 mr-5 mb-4 flex h-1 items-center"
                      data-testid="closeButton"
                    >
                      <IconClose />
                    </div>
                  </div>
                  {modalConfig.title && (
                    <div
                      className={`mx-20 flex ${
                        modalConfig.titleWidth
                          ? modalConfig.titleWidth
                          : "max-w-sm"
                      } items-center justify-center px-0 text-center text-2xl font-bold lg:mx-28 lg:px-9`}
                      data-testid="modal-title"
                    >
                      {modalConfig.title}
                    </div>
                  )}
                  {modalConfig.body && (
                    <div className="flex items-center justify-center pt-6">
                      {modalConfig.body}
                    </div>
                  )}
                  <div className="mb-5 flex flex-col items-center justify-center rounded-b border-solid">
                    {modalConfig.btnConfig.map(
                      (btn: BtnConfig, index: number) => {
                        return (
                          <div className="mb-3 flex" key={index}>
                            <Button
                              variant={
                                btn.variant as
                                  | "primary"
                                  | "secondary"
                                  | "dark"
                                  | "default"
                              }
                              mode={
                                btn.mode as "outlined" | "contained" | "link"
                              }
                              size={btn.size as "sm" | "lg" | "md"}
                              type={
                                btn.type as
                                  | "button"
                                  | "submit"
                                  | "reset"
                                  | undefined
                              }
                              classes={btn.classes && btn.classes}
                              key={index}
                              aria-label={btn.name}
                              onClick={() => {
                                // setDialog(false);
                                btn.callback && btn.callback();
                              }}
                            >
                              {btn.name}
                            </Button>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

Modal.displayName = "Modal";
