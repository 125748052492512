export const PET = {
  MILESTONE_TRACKER: "pet.milestoneTracker",
  HOME: "pet.home",
  HEADING: "pet.heading",
  CHANGE_PHOTO: "pet.changePhoto",
  PASSWORD_CHANGE: "pet.passwordChange",
  MANAGE_PREFERENCES: "pet.managePreferences",
  SAVE: "common.save",
  HIDDEN_PETS_HEADING: "pet.hiddenPetsHeading",
  EDIT_PROFILE: "pet.editProfile",
  MILESTONE_BUTTON_HEADING: "pet.milestoneButtonHeading",
  MILESTONE_BUTTON_TEXT: "pet.milestoneButtonText",
  FOOD_QUIZ_HEADING: "pet.foodQuizHeading",
  FOOD_QUIZ_BUTTON_TEXT: "pet.foodQuizButtonText",
  ADD_PET: "pet.addPet",
  ADD_NEW_PET: "pet.addNewPet",
};
