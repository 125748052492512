export const API_URLS = {
  PARENT_PROFILE: "/v1/parent/profile",
  PET_CONFIG: "/v1/pets/references",
  PETS: "/v1/pets",
  LIFESTAGES: "/lifestages",
  MILESTONES: "/milestones",
  MILESTONE: "/milestone",
  ARTICLES: "/v1/recommended-articles",
};

export const GENERATE_MILESTONES_PATH = (
  petId: string,
  lifestageId: string
) => {
  return `/${petId}${API_URLS.LIFESTAGES}/${lifestageId}${API_URLS.MILESTONES}`;
};
