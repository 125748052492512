export const ADD_EDIT_PET = {
  CHANGE_PHOTO: "common.changePhoto",
  UPLOAD_PHOTO: "common.uploadPhoto",
  BREED: "addEditPet.breed",
  AGE: "addEditPet.age",
  PETS_NAME: "addEditPet.petsName",
  PETS_NICKNAME: "addEditPet.petsNickname",
  WHAT_KIND_OF_PET_DO_YOU_HAVE: "addEditPet.whatKindOfPetDoYouHave",
  PETS_GENDER: "addEditPet.petsGender",
  PETS_BIRTHDAY: "addEditPet.petsBirthday",
  PETS_AGE: "addEditPet.petsAge",
  PETS_AGE_YEAR: "addEditPet.petsAgeYear",
  PETS_AGE_MONTH: "addEditPet.petsAgeMonth",
  YEAR: "addEditPet.year",
  YEARS: "addEditPet.years",
  PET: "addEditPet.pet",
  ACTUAL_DATE: "addEditPet.actualDate",
  APPROXIMATE_DATE: "addEditPet.approximateDate",
  PETS_ADOPTION_DATE: "addEditPet.petsAdoptionDate",
  PETS_LIFE_STAGE: "addEditPet.petsLifeStage",
  PETS_VET: "addEditPet.petsVet",
  S_VET: "addEditPet.sVet",
  COMMON_ERROR_WARNING: "addEditPet.commonErrorWarning",
  HIDE_PET: "addEditPet.hidePet",
  REMOVE_PET: "addEditPet.removePet",
  UNHIDE_PET: "addEditPet.unhidePet",
  FOOD_QUIZ: "addEditPet.foodQuiz",
  UPDATE: "common.update",
  SAVE: "common.save",
  DISCARD_CHANGES: "common.discardChanges",
  REMOVED: "common.removed",
  ADDED: "common.added",
  FOR_DOGS: "addEditPet.forDogs",
  FOR_CATS: "addEditPet.forCats",
  PET_AGE_MAX_WARNING_1: "addEditPet.petAgeMaxWarning1",
  PET_AGE_MAX_WARNING_2: "addEditPet.petAgeMaxWarning2",
  PET_AGE_MAX_WARNING_3: "addEditPet.petAgeMaxWarning3",
  PET_SIZE_ERROR: "addEditPet.petSizeError",
  PET_BREED_ERROR: "addEditPet.petBreedError",
  OPTIONAL_TEXT: "addEditPet.optionalText",
  PET_SIZE: "addEditPet.petsSize",
  NOT_SELECTED_TEXT: "addEditPet.notSelectedText"
};
