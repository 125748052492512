import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, ButtonWide } from "ui";
import { scrollToElement } from "utils";
import { PetType } from "../../../../../types";
import { PET } from "../../../../constants";
import PetDetails, { PetName } from "../PetDetails";

export default function HiddenPets({
  hiddenPets,
}: {
  hiddenPets: PetType[];
  callBackAfterShowHiddenPets?: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [showHiddenPets, setShowHiddenPets] = useState(false);
  const hiddenPetref = useRef<HTMLDivElement>(null);

  const toggleHiddenPets = () => {
    setShowHiddenPets(!showHiddenPets);
    !showHiddenPets && scrollToHiddenPets();
  };

  const scrollToHiddenPets = () => {
    const timeOut = setTimeout(() => {
      scrollToElement(hiddenPetref.current);
      clearTimeout(timeOut);
    }, 10);
  };

  return (
    <>
      {showHiddenPets ? (
        <div className="mt-16">
          <h2 className="mb-8 text-2xl font-extrabold md:text-3xl lg:mb-8">
            {t(PET.HIDDEN_PETS_HEADING)}
          </h2>
          <Accordion
            name="hiddenPet"
            variant="dark"
            items={hiddenPets}
            header={(item: PetType) => (
              <PetName name={item.petNickName || item.petName} />
            )}
            content={(item: PetType) => <PetDetails data={item} />}
            parentClasses="mt-6"
            openIndex={hiddenPets.length}
          />
        </div>
      ) : null}

      {hiddenPets.length > 0 ? (
        <>
          <ButtonWide
            aria-label={`${
              showHiddenPets ? t("common.hide") : t("common.show")
            } ${t(PET.HIDDEN_PETS_HEADING)}`}
            mode="link"
            size="md"
            variant="primary"
            onClick={toggleHiddenPets}
          >
            {showHiddenPets ? t("common.hide") : t("common.show")}{" "}
            {t(PET.HIDDEN_PETS_HEADING)}
          </ButtonWide>
        </>
      ) : null}
      <div ref={hiddenPetref}></div>
    </>
  );
}
