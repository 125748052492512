import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Breadcrumb = (props: {
  config: { label: string; path: string }[];
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLast = (index: number) => {
    return index === Object.keys(props.config).length - 1;
  };

  return (
    <nav className="mb-4">
      <ol className="flex text-xs">
        {props.config.map(
          (breadcrumbItem: { label: string; path: string }, index: number) => {
            const disabled = isLast(index) ? "disabled" : "";

            return (
              <li key={index}>
                <button
                  disabled={disabled ? true : false}
                  className={!disabled ? `text-brand-primary` : ""}
                  onClick={() =>
                    navigate(breadcrumbItem.path, { replace: true })
                  }
                >
                  {t(breadcrumbItem.label)}
                </button>
                {!disabled && <>&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</>}
              </li>
            );
          }
        )}
      </ol>
    </nav>
  );
};

Breadcrumb.displayName = "Breadcrumb";
