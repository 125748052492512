import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "utils";
import axios from "axios";
import { API_URLS } from "../../constants/apiUrls";
import { Article } from "../../../types";
import ArticleTile from "./ArticleTile";
import useRequireAuth from "../../hooks/useRequireAuth";

export default function RecommendedArticles() {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [articles, setArticles] = useState<Article[]>([]);
  const { t } = useTranslation();
  const auth = useRequireAuth();

  //Api configs
  //fetch pet Api
  const { exec: fetchArticlesData } = useApi(() => {

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    return axios
      .get(`${process.env.REACT_APP_ARTICLE_API_URL}${API_URLS.ARTICLES}`, {
        signal: abortController.signal,
        headers: { language: auth.locale as string },
      })
      .then((apiResponse) => {
        if (apiResponse.data.serviceStatus.code === 200) {
          setArticles(apiResponse.data.data);
        }
      });
  });

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    fetchArticlesData();
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  return (
    <>
      {articles.length ? (
        <div className="py-14 lg:py-8">
          <h2 className="mb-4 w-4/5 text-2xl font-extrabold md:w-full md:text-3xl  lg:mb-9">
            {t(`addEditPet.recommendedArticles`)}
          </h2>

          <div className="flex flex-col">
            <div
              className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2"
              role="list"
              aria-label="Recommended Articles"
            >
              {articles.map((article: Article) => (
                <ArticleTile article={article} key={article.id} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
