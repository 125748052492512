import { useEffect, useState } from "react";
const scrollThreshold = 400;
export const useScrollDirection = () => {
  const [position, setPosition] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > scrollThreshold ? "down" : "up";

      if (
        direction !== position &&
        (scrollY - scrollThreshold > 10 || scrollY - scrollThreshold < -10)
      ) {
        setPosition(direction);
        setVisible(direction === "down");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [position]);
  return { position, visible };
};
