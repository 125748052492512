import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MESSAGES } from "../../../constants";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { Modal } from "../../../lib/modal";
import { HideAndRemoveModalProps } from "../types";
import DeletePetOptionsForm from "./DeletePetOptionsForm";
import HidePetOptionsForm from "./HidePetOptionsForm";
import DeletePetConfirmOptionsForm from "./DeletePetConfirmOptionsForm";

export default function HideDeleteModals({
  hideAndRemovePetApi,
  petId,
  setShowReasonDialogDelete,
  showReasonDialogDelete,
  hideDialogOpen,
  setHideDialogOpen,
}: HideAndRemoveModalProps) {
  const { t } = useTranslation();
  const { petStatusByName } = usePetConfig();
  const [reasonForDeleting, setReasonForDeleting] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deletePermanently = () => {
    hideAndRemovePetApi([
      {
        petId: petId,
        petStatusId: petStatusByName.Deleted,
        petRemovalReason: reasonForDeleting,
      },
    ]);
  };

  const hidePet = (reason: string) => {
    hideAndRemovePetApi([
      {
        petId: petId,
        petStatusId: petStatusByName.Remembered,
        petRemovalReason: reason,
      },
    ]);
  };
  //final delete modal configuration
  const modalConfigReasonDelete = {
    title: t(MESSAGES.PET_REMOVAL_REASON_TITLE),
    body: (
      <DeletePetOptionsForm
        setReasonForDeleting={setReasonForDeleting}
        setShowReasonDialogDelete={setShowReasonDialogDelete}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
      />
    ),
    btnConfig: [],
  };

  // hide modal configuration
  const hideModalConfig = {
    title: t(MESSAGES.PET_HIDE_REASON_TITLE),
    body: (
      <HidePetOptionsForm
        hidePet={hidePet}
        setHideDialogOpen={setHideDialogOpen}
      />
    ),
    btnConfig: [],
    titleWidth: "w-90",
  };

  // delete confirmation modal
  const modalConfigDeleteConfirm = {
    title: t(MESSAGES.PET_REMOVAL_REASON_TITLE),
    body: (
      <DeletePetConfirmOptionsForm
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        deletePermanently={deletePermanently}
      />
    ),
    btnConfig: [],
  };
  return (
    <>
      <Modal
        modalConfig={modalConfigReasonDelete}
        dialogState={showReasonDialogDelete}
        setDialog={setShowReasonDialogDelete}
      />
      <Modal
        modalConfig={modalConfigDeleteConfirm}
        dialogState={confirmationDialogOpen}
        setDialog={setConfirmationDialogOpen}
      />
      <Modal
        modalConfig={hideModalConfig}
        dialogState={hideDialogOpen}
        setDialog={setHideDialogOpen}
      />
    </>
  );
}
