export const LifestageMilestone = {
  BACK: "common.back",
  MILESTONES: "lifestageMilestone.milestones",
  LIFESTAGE: "lifestageMilestone.lifestage",
  COMPLETED_DATE: "lifestageMilestone.completedDate",
  PAST_MILESTONES: "lifestageMilestone.pastMilestones",
  NEXT_MILESTONES: "lifestageMilestone.nextMilestones",
  TITLE: "lifestageMilestone.title",
  UPDATE_SUCCESS: "lifestageMilestone.updateSuccess",
  UPDATE_FAILED: "common.updateFailed",
};
