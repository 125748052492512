import { Button, Card } from "ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { calculateAgeApprox } from "utils";

import { PetType } from "../../../../types";
import { HideAndRemovePetApiOption } from "../types";
import { usePetConfig } from "../../../hooks/usePetConfig";
import { ROUTES_PATH } from "../../../constants/routes";
import PetProfilePhoto from "../PetProfilePhoto";

const PetTile = ({
  pet,
}: {
  pet: PetType;
  hideAndRemovePetApi?: (options: HideAndRemovePetApiOption[]) => void;
  loading?: { petId?: string; petStatusId?: number };
}) => {
  const navigate = useNavigate();
  const { getBreed } = usePetConfig();
  const { t } = useTranslation();

  return (
    <Card
      role="listitem"
      classes="flex-col w-full relative border border-brand-secondary"
      aria-label={"Your Pet " + pet.petName}
    >
      <PetProfilePhoto
        src={pet.petImage}
        size="lg"
        rounded="t-2xl"
        petName={pet.petName}
        background="bg-brand-accent-gray-light h-[45%]"
      />
      <div className="grid rounded-b-2xl">
        <div className="m-2.5 mt-7 text-center">
          <h2 className="mb-3 text-3xl font-extrabold capitalize">
            {pet.petNickName || pet.petName}
          </h2>
          <div className="mx-auto mb-2 mt-4 w-44 border-t border-brand-accent-gray-light"></div>
          <p className="text-lg font-normal">
            {pet.petBreedId && getBreed && getBreed(pet.petBreedId)?.petBreedName} /{" "}
            {pet.petBirthday && calculateAgeApprox(pet.petBirthday, t)}
          </p>
        </div>
        <Button
          classes="mt-10 capitalize"
          data-testid="View profile"
          mode="link"
          variant="primary"
          aria-label={`View ${pet.petName}'s Profile`}
          onClick={() =>
            navigate(ROUTES_PATH.PET, {
              state: { petId: pet.petId },
              replace: false,
            })
          }
        >
          {t("common.view")} {pet.petName}
        </Button>
      </div>
    </Card>
  );
};

export default PetTile;
