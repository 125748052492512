import { PET } from "./pet";
import { PROFILE } from "./profile";

export const ROUTES = {
  ROOT: "/",
  PROFILE: "profile",
  PET: "pet",
  MILESTONE: "milestone/:petId/:lifestageId",
  DASHBOARD: "dashboard",
  PET_DASHBOARD: "pets",
  ADD_PET: "add-pet",
  AUTH_WIDGET_DEMO: "auth-widget-demo",
  ERROR_PAGE: "error"
};

export const ROUTES_PATH = {
  PROFILE: ROUTES.ROOT + ROUTES.PROFILE,
  PET: ROUTES.ROOT + ROUTES.PET,
  MILESTONE: ROUTES.ROOT + "milestone",
  DASHBOARD: ROUTES.ROOT + ROUTES.DASHBOARD,
  PET_DASHBOARD: ROUTES.ROOT + ROUTES.PET_DASHBOARD,
  ADD_PET: ROUTES.ROOT + ROUTES.ADD_PET,
  AUTH_WIDGET_DEMO: ROUTES.ROOT + ROUTES.AUTH_WIDGET_DEMO,
  ERROR_PAGE: ROUTES.ROOT+ROUTES.ERROR_PAGE
};

export const BREADCRUMB_CONFIG = {
  home: {
    label: PET.HOME,
    path: ROUTES_PATH.DASHBOARD,
  },
  petDashboard: {
    label: PET.HEADING,
    path: ROUTES_PATH.PET_DASHBOARD,
  },
  dashboard: {
    label: "Home",
    path: ROUTES_PATH.DASHBOARD,
  },
  milestones: {
    label: PET.MILESTONE_TRACKER,
    path: ROUTES_PATH.MILESTONE,
  },
  pet: {
    label: "",
    path: ROUTES_PATH.PET,
  },
  addPet: {
    label: PET.ADD_PET,
    path: ROUTES_PATH.ADD_PET,
  },
  myProfile: {
    label: PROFILE.HEADING,
    path: ROUTES_PATH.PROFILE,
  },
};
